'use client'

import { SignOutButton } from '@clerk/nextjs'
import { datadogLogs } from '@datadog/browser-logs'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useEffect } from 'react'

import ErrorTemplate from '~/modules/error-template'
import { publicConfig } from '~/config'
import { Button } from '~/ui/shadcn/ui/button'

const Error = ({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) => {
	useEffect(() => {
		datadogLogs.logger.error('Error occured', {}, error)
	}, [
		error,
	])

	const isTrestleStaff = useFeatureFlagEnabled('trestle_staff')

	const message = publicConfig.app.isDev ? error.message : 'Something went wrong!'

	return (
		<>
			{isTrestleStaff || publicConfig.app.isDev ? (
				<div className="flex flex-row items-center justify-end gap-2 px-6">
					<a href="/select-organization">
						<Button
							variant="outline"
							size="sm"
							className="mt-4"
						>
							Switch Organization
						</Button>
					</a>
					<SignOutButton>
						<Button
							variant="outline"
							size="sm"
							className="mt-4"
						>
							Logout
						</Button>
					</SignOutButton>
				</div>
			) : null}
			<ErrorTemplate
				type="error"
				message={message}
				onClick={() => {
					reset()
				}}
			/>
		</>
	)
}

export default Error
